<template>
  <div class="case-details">
    <div class="public-top">
      <div class="public-img">
        <p> 心所向 至未来 — 华美互通 </p>
      </div>
    </div>
    <div class="case-details-item">
      <div class="label">{{page.label}}</div>
      <el-divider></el-divider>
      <div class="titletime">{{page.titletime}}</div>
      <div class="case-details-content">
        <div v-html="page.primary_coverage"></div>
      </div>
      <el-divider></el-divider>
    </div>
  </div>
</template>

<script>
import { data } from '@/api/home'

export default {
  name: 'case-details',
  data () {
    return {
      id: this.$route.params.id,
      page: {}
    }
  },
  created () {
    let ldata = data()
    let qdata = ldata.find(item => item.id == this.id)
    this.page = qdata
  }
}
</script>

<style lang="scss" scoped>
.case-details {
  .case-details-item {
    margin: 0 auto;
    width: 90%;
    .label {
      margin-top: 2rem;
      text-align: center;
      font-size: 1.5rem;
    }
    .el-divider--horizontal {
      margin: 0.5rem 0;
      height: 0.2rem;
      background: #ff6d00;
    }
    .titletime {
      text-align: center;
      margin-bottom: 2rem;
      font-size: 1rem;
      font-weight: 300;
      color: #737373;
    }
    .case-details-content {
      margin-bottom: 2rem;
      > div {
        ::v-deep {
          img {
            width: 100% !important;
            height: 100% !important;
          }
          iframe {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>