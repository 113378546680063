<template>
  <div class="trends">
    <div class="public-top">
      <div class="public-img">
        <p> 心所向 至未来 — 华美互通 </p>
      </div>
    </div>
    <div class="trends-item">
      <div class="label">企业动向</div>
      <div class="trendsList">
        <template v-for="(item,index) in trendsList">
          <router-link :to="`/MobileTrends/trend-details/${item.id}`"
                       :key="index"
                       custom
                       v-slot="{ navigate }">
            <el-link @click="navigate"
                     @keypress.enter="navigate"
                     :underline="false">
              <div>
                <el-image style="width: 100%; height: 60%;"
                          :src="item.mobileImg?item.mobileImg:item.img"
                          fit="cover"
                          lazy></el-image>
              </div>
              <div>
                <p>{{item.titletime}}</p>
                <p>{{item.label}}</p>
              </div>
              <div><i class="el-icon-arrow-right" /></div>
            </el-link>
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { data } from '@/api/home'
export default {
  name: 'Trends',
  data () {
    return {
      trendsList: data()
    }
  }
}
</script>

<style lang="scss" scoped>
.trends {
  .trends-item {
    margin: 0 auto;
    width: 90%;
    .label {
      margin-top: 2rem;
      text-align: center;
      font-size: 1.8rem;
    }
    .trendsList {
      margin: 0 auto;
      width: 100%;
      margin-top: 2rem;
      > a {
        display: flex;
        height: 3.54rem;
        padding: 1rem;
        border: 0.1px dashed #ff6e01;
        box-shadow: 0.4rem 0.6rem 0.7rem 0.1rem rgba(255, 110, 1, 0.1);
        border-radius: 0.3rem;
        &.el-link--default:hover {
          color: #ff6d00;
        }
        &:nth-child(n + 2) {
          margin-top: 1.5rem;
        }
        ::v-deep {
          > span {
            width: 100%;
            display: flex;
            align-items: center;
            // width: 100%;
            > div {
              &:nth-child(1) {
                display: flex;
                align-items: center;
                width: 25%;
                height: 40%;
              }
              &:nth-child(2) {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding-left: 1rem;
                width: 70%;
                > p:nth-child(1) {
                  font-size: 0.8rem;
                }
                > p:nth-child(2) {
                  font-size: 0.9rem;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
              &:nth-child(3) {
                color: #ff6d00;
                > i {
                  font-size: 1.2rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>