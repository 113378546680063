<template>
  <div class="Join-Us">
    <div class="public-top">
      <div class="public-img3">
        <p>点燃激情 扬帆起航 — 华美互通 </p>
      </div>
    </div>
    <div class="Join-Us-item">
      <div class="Join-Us-img">
        <el-image :src="'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/join_us/join_us.png'"
                  fit="cover"
                  lazy></el-image>
      </div>
      <div class="Join-Us-dq">
        <el-image :src="'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/join_us/join_us_dq.png'"
                  fit="cover"
                  lazy></el-image>
        <span>数据挖掘工程师</span>
        <span>广告投放实习生</span>
        <span>电商文案策划</span>
        <span>AE执行</span>
        <span>媒介助理</span>
        <span>数据运营总监</span>
        <span>资深数据分析师</span>
      </div>
      <div class="Recruitment-position">
        <p>招聘职位</p>
        <span class="title-icon">>></span>
        <div>
          <el-link :underline="false"
                   href="https://www.zhipin.com/job_detail/?query=%E5%8C%97%E4%BA%AC%E5%8D%8E%E7%BE%8E%E4%BA%92%E9%80%9A%E5%B9%BF%E5%91%8A%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8&city=100010000&industry=&position="
                   target="_blank">
            <span class="title-bg">BOSS直聘</span>
          </el-link>
          <el-link :underline="false"
                   href="https://www.liepin.com/company/12320445/"
                   target="_blank">
            <span class="title-bg">猎聘网</span>
          </el-link>
        </div>
      </div>
      <el-card class="Join-Us-Contact">
        <div>电话：{{page.phone}}</div>
        <div>邮箱：{{page.mailbox}}</div>
        <div>地址：{{page.address}}</div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Join-Us',
  data () {
    return {
      page: {
        phone: '15600138075',
        mailbox: 'lixin@huameihutong.com',
        address: '北京市大兴亦庄经济技术开发区荣华南路1号国锐金道广场A座7层'
      }
    }
  },
  mounted () {
    if (JSON.stringify(this.$route.params) != '{}' && this.$route.params.t_f) {
      this.info()
    }
  },
  methods: {
    info () {
      setTimeout(() => {
        window.scrollTo(0, 500000);
      }, 100)
    }
  },
}
</script>

<style lang="scss" scoped>
.Join-Us {
  .Join-Us-item {
    margin: 0 auto;
    width: 90%;
    .Join-Us-img {
      margin: 0 auto;
      width: 85%;
      margin-top: 2rem;
    }
    .Join-Us-dq {
      margin-top: 1rem;
      padding: 3rem;
      position: relative;
      > span {
        position: absolute;
        &:nth-child(2) {
          top: 2%;
          right: 25%;
        }
        &:nth-child(3) {
          top: 12%;
          left: 0%;
        }
        &:nth-child(4) {
          top: 16%;
          right: -1%;
        }
        &:nth-child(5) {
          top: 43%;
          right: 0;
        }
        &:nth-child(6) {
          top: 50%;
          left: 0;
        }
        &:nth-child(7) {
          bottom: 18%;
          right: 0%;
        }
        &:nth-child(8) {
          bottom: 9%;
          left: 10%;
        }
      }
    }
    .Recruitment-position {
      text-align: center;
      margin-top: 2rem;
      p {
        position: relative;
        font-size: 1.5rem;
        font-weight: 400;
        color: #000000;
      }
      .title-icon {
        margin-top: 0.5rem;
        margin-bottom: 0.4rem;
        display: block;
        font-size: 2rem;
        font-weight: bold;
        color: #f8862d;
        transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        /* Internet Explorer 9*/
        -moz-transform: rotate(270deg);
        /* Firefox */
        -webkit-transform: rotate(270deg);
        /* Safari 和 Chrome */
        -o-transform: rotate(270deg);
        /* Opera */
      }
      div {
        > a {
          width: 50%;
          ::v-deep {
            > span {
              display: inline-flex;
              justify-content: center;
              .title-bg {
                display: inline-flex;
                justify-content: center;
                flex-direction: column;
                width: 9rem;
                height: 2.8rem;
                font-size: 1.5rem;
                font-weight: 400;
                border-radius: 5rem;
                color: #ffffff;
                background: url("https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/join_us/xbg.png")
                  no-repeat center top;
                background-size: 100% 100% !important;
              }
            }
          }
        }
      }
    }
    .Join-Us-Contact {
      margin-top: 2rem;
      > .el-card__body {
        > div + div {
          margin-top: 0.5rem;
        }
      }
    }
  }
}
</style>