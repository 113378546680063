<template>
  <div class="home">
    <div class="home-top-bg bg">
      <p>数投运商业生态服务</p>
      <p>华美已经发展成「数据策略型品牌全案」机构，并以数据策略、品牌全案、创意营销、电商运营四大业务板块贯穿了品牌的全生态服务。</p>
      <router-link class="bom-but"
                   :to="{ name:'MobileRecruit', params:{t_f:true} }"
                   custom
                   v-slot="{ navigate }">
        <el-button @click="navigate"
                   @keypress.enter="navigate"
                   class="el-but">联系我们</el-button>
      </router-link>
    </div>
    <div class="home-Timeline bg"
         ref="timebg">
      <el-row class="timeline-top">
        <template v-for="(item,index) in timeline_top">
          <el-col :key="index"
                  v-if="index<3"
                  :span="8">
            <p class="titletime">{{item.titletime}}</p>
            <p class="label">{{item.label}}</p>
            <p class="title"
               v-html="item.title"></p>
          </el-col>
        </template>
      </el-row>
      <el-row class="timeline-bom">
        <template v-for="(item,index) in timeline_top">
          <el-col :key="index"
                  v-if="index>=3"
                  :span="8">
            <p class="titletime">{{item.titletime}}</p>
            <p class="label">{{item.label}}</p>
            <p class="title"
               v-html="item.title"></p>
          </el-col>
        </template>
      </el-row>
    </div>
    <!-- 主营业务 -->
    <div class="home-Main-business">
      <div class="label-title">
        <div>主营业务</div>
      </div>
      <div class="Main-business">
        <template v-for="(item,index) in businessList">
          <el-radio :key="index"
                    v-model="Main_business"
                    :label="item.id"
                    border>
            <div>
              <el-image style="width: 2rem; height: 3rem;"
                        :src="item.icon"
                        fit="cover"
                        lazy></el-image>
              <span>{{item.label}}</span>
            </div>
          </el-radio>
        </template>
        <div class="Main-business-item">
          <!-- 数据业务 -->
          <template v-for="(item,index) in businessList">
            <div :class="`item${index+1}`"
                 :key="index"
                 v-if="Main_business == (index+1)">
              <div class="item-title">
                <div>
                  <p>{{item.toplabel}}</p>
                  <p>{{item.title}}</p>
                  <div>
                    <template v-for="(im,i) in item.titleList">
                      <p :key="i">{{im}}</p>
                    </template>
                  </div>
                </div>
              </div>
              <el-image :src="item.img" />
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- 案例展示 -->
    <div class="home-Case-display">
      <div class="label-title">
        <div>案例展示</div>
      </div>
      <div class="caseList">
        <template v-for="(item,index) in caseList">
          <XRouterLink :key="index"
                       :to="item.hrefs?item.hrefs:`/UserCase/CaseDetails/${item.id}`"
                       :isindex="item.hrefs?0:1"
                       v-if="index<6">
            <el-image style="width: 100%; height: 100%;"
                      :src="item.img"
                      fit="cover"
                      lazy></el-image>
            <span class="content">
              {{item.label}}
            </span>
          </XRouterLink>
          <!-- <router-link :to="`MobileCase/MobileCase-details/${item.id}`"
                       :key="index"
                       v-if="index<6">
            <el-image style="width: 100%; height: 100%;"
                      :src="item.img"
                      fit="cover"
                      lazy></el-image>
            <span>
              {{item.label}}
            </span>
          </router-link> -->
        </template>
      </div>
      <router-link class="bom-but"
                   to="/MobileCase"
                   custom
                   v-slot="{ navigate }">
        <el-link @click="navigate"
                 @keypress.enter="navigate"
                 :underline="false">了解更多></el-link>
      </router-link>
    </div>
    <!-- 企业荣誉 -->
    <div class="home-honor">
      <div class="label-title">
        <div>企业荣誉</div>
      </div>
      <div class="honorList">
        <el-carousel trigger="click"
                     height="30rem">
          <el-carousel-item v-for="(item,index) in MobileHonorList"
                            :key="index">
            <el-image style="width: 100%; height: 60%;"
                      :src="item.img"
                      fit="cover"></el-image>
            <div>{{item.label}}</div>
            <div v-html="item.title"></div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div class="home-partner">
      <div class="label-title">
        <div>合作伙伴</div>
      </div>
      <div class="partnerList">
        <template v-for="(item,index) in partnerList">
          <div class="partner-item-item"
               :key="index">
            <el-image :src="item"
                      :style="`width:${partnerwh[index].w}rem;height:${partnerwh[index].h}rem`"
                      fit="cover"
                      lazy></el-image>
          </div>
        </template>
      </div>
    </div>
    <!-- 企业动向 -->
    <div class="home-Enterprise-trends">
      <div class="label-title">
        <div>企业动向</div>
      </div>
      <div class="trendsList">
        <template v-for="(item,index) in trendsList">
          <router-link :to="`/MobileTrends/trend-details/${item.id}`"
                       :key="index"
                       v-if="index>2"
                       custom
                       v-slot="{ navigate }">
            <el-link @click="navigate"
                     @keypress.enter="navigate"
                     :underline="false">
              <div>
                <el-image style="width: 100%; height: 60%;"
                          :src="item.mobileImg?item.mobileImg:item.img"
                          fit="cover"
                          lazy></el-image>
              </div>
              <div>
                <p>{{item.titletime}}</p>
                <p>{{item.label}}</p>
              </div>
              <div><i class="el-icon-arrow-right" /></div>
            </el-link>
          </router-link>
        </template>
      </div>
      <router-link class="bom-but"
                   to="/MobileTrends"
                   custom
                   v-slot="{ navigate }">
        <el-link @click="navigate"
                 @keypress.enter="navigate"
                 :underline="false">了解更多></el-link>
      </router-link>
    </div>
    <div class="Footer"
         :style="`background:url(${page.bottomimg}) no-repeat;`">
      <div>
        <p>{{page.corporate_name}}</p>
        <p>地址：{{page.corporate_address}}</p>
        <p>电话：{{page.contact_information}}</p>
        <el-image style="width: 63px; height: 63px"
                  :src="page.QR_code"
                  fit="cover"
                  lazy></el-image>
      </div>
    </div>
  </div>
</template>

<script>
import { timeline, businessList, caseList, data, HonorList, partnerList } from '@/api/home'
import XRouterLink from '@/components/XRouterLink'

export default {
  name: '',
  components: { XRouterLink },
  data () {
    return {
      timeline_top: timeline(),
      // 选中数据
      Main_business: 1,
      businessList: businessList(),
      caseList: caseList(),
      data: data(),
      MobileHonorList: HonorList(),
      // 合作伙伴图表宽高
      partnerList: partnerList(),
      partnerwh: [
        { w: 4.1, h: 4.1 }, { w: 4, h: 4 }, { w: 9, h: 1.9 }, { w: 6, h: 3 }, { w: 6, h: 2 },
        { w: 5, h: 4 }, { w: 7, h: 2.3 }, { w: 5, h: 2 }, { w: 7, h: 4 }, { w: 5, h: 2.8 },
        { w: 4, h: 4 }, { w: 5.5, h: 4 }, { w: 7, h: 2.5 }, { w: 5, h: 4 }, { w: 5, h: 4 },
        { w: 6, h: 4 }, { w: 5, h: 3 }, { w: 7, h: 2.3 }, { w: 7, h: 2 }, { w: 7, h: 3 },
      ],
      // 企业动向
      trendsList: data(),
      page: {
        bottomimg: 'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/Mobile-bg-bottom.png',
        corporate_name: '北京华美互通广告有限公司 ',
        corporate_address: '北京市大兴亦庄经济技术开发区荣华南路1号国锐·金嵿广场A座7层',
        contact_information: '15600138075（同微信）',
        QR_code: 'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/QR_code.png',
      }
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
.home {
  // 最上方背景图片
  .home-top-bg {
    text-align: center;
    height: 40rem;
    background: url("https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/Mobile-terminal/top-bg.png")
      no-repeat;
    > p {
      text-align: center;
      &:nth-child(1) {
        padding: 4rem 0 2.2rem 0;
        font-size: 1.6rem;
      }
      &:nth-child(2) {
        padding: 0 2.5rem;
        font-size: 0.8rem;
        line-height: 1.6rem;
      }
    }
    .el-but {
      margin-top: 2rem;
      width: 9rem;
      height: 3rem;
      color: #ff6d00;
      border: 0.1rem solid #ff6d00;
      background: #ffffff;
      &:focus {
        background: #fff0e5;
      }
    }
  }
  // 时间线
  .home-Timeline {
    margin: 2rem auto;
    width: 90%;
    // height: 100%;
    height: 25rem;
    background-image: url("https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/icon/Mobile/Timeline.png");
    background-repeat: no-repeat;
    background-position: 0px 0px;
    background-size: 100% 100%;
    > div {
      text-align: center;
    }
    .timeline-top {
      height: 15rem;
      font-size: 0.5rem;
      > div {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        height: 100%;
        top: 31%;
        &:nth-child(even) {
          top: 7%;
          justify-content: flex-end;
          flex-direction: column-reverse;
        }
      }
      > div:nth-child(1) {
        padding-left: 2%;
      }
      > div:nth-child(3) {
        padding-right: 2%;
      }
    }

    .timeline-bom {
      height: 15rem;
      display: flex;
      justify-content: flex-start;
      direction: rtl;
      > div {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        flex-direction: column;
        height: 100%;
        top: 22%;
        &:nth-child(odd) {
          top: -68%;
          flex-direction: column-reverse;
        }

        &:nth-child(1) {
          padding-right: 2%;
        }
        &:nth-child(3) {
          padding-left: 2%;
        }
      }
    }

    .titletime {
      font-size: 1.2rem;
      font-weight: bold;
      color: #0e0e0e;
    }
    .label {
      font-size: 1.2rem;
      font-weight: bold;
      color: #ff6e01;
    }
    .title {
      font-size: 0.6rem;
    }
  }
  // 主营业务
  .home-Main-business {
    .Main-business {
      margin: 2rem auto;
      width: 90%;
      ::v-deep {
        .el-radio {
          color: #0e0e0e;
          &:hover {
            background: #fff8f2;
          }
          margin-right: 0;
          .el-radio__label {
            color: #0e0e0e;
            > div {
              display: flex;
              align-items: center;
              flex-direction: column;
              > .el-image {
                margin-bottom: 0.8rem;
              }
            }
          }
          &.is-bordered {
            width: 20.5%;
            height: 6.5rem;
            padding: 0;
            border: 1px dashed #ff6d00;
            border-radius: 0.5rem;
            .el-radio__input {
              display: none;
            }
            + .el-radio.is-bordered {
              margin-left: 6% !important;
            }
            &.is-checked {
              color: #0e0e0e !important;
              background: #fff8f2;
            }
          }
        }
      }
      .Main-business-item {
        margin-top: 2rem;
        .item-title {
          > div {
            > p:nth-child(1) {
              font-size: 2rem;
              color: #ff6d00;
              &::after {
                content: "";
                display: block;
                width: 16.6rem;
                height: 0.1rem;
                background: #ff6d00;
              }
            }
            > p:nth-child(2) {
              margin-top: 1.5rem;
              line-height: 1.8rem;
            }
            > div:nth-child(3) {
              p {
                position: relative;
                display: flex;
                align-items: center;
                padding-left: 1.2rem;
                font-size: 1.2rem;
                color: #000000;
                line-height: 2.4rem;
                &::before {
                  position: absolute;
                  display: block;
                  left: 0;
                  content: "";
                  border-radius: 50%;
                  width: 8px;
                  height: 8px;
                  color: #ff6d00;
                  background: #ff6d00;
                }
              }
            }
          }
        }
      }
    }
  }
  // 案例展示
  .home-Case-display {
    margin: 0 auto;
    margin-bottom: 2rem;
    width: 90%;
    .caseList {
      overflow: hidden;
      zoom: 1;
      margin-top: 2.5rem;
      a {
        position: relative;
        display: block;
        float: left;
        margin-top: 6%;
        width: 47%;
        height: 10rem;
        ::v-deep {
          > div {
            border-radius: 0.4rem;
          }
        }
        &:nth-child(even) {
          margin-left: 6%;
        }
        .content {
          position: absolute;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          left: 0;
          bottom: 0;
          font-size: 1rem;
          color: #ffffff;
          background: #000000;
          background-color: rgba(0, 0, 0, 0.6);
          border-bottom-right-radius: 0.4rem;
          border-bottom-left-radius: 0.4rem;
        }
      }
    }
  }
  // 企业荣誉
  .home-honor {
    position: relative;
    margin: 0 auto;
    margin-bottom: 3rem;
    width: 90%;
    .honorList {
      margin-top: 2rem;
      margin-bottom: 2rem;
      padding: 0.8rem;
      border: 0.1rem dashed #ff6d00;
      ::v-deep {
        .el-carousel {
          position: inherit;
          .el-carousel__container {
            .el-carousel__item {
              background-color: #ffffff;
              > div:nth-child(2) {
                text-align: center;
                margin-top: 0.8rem;
                padding: 0.5rem 0;
                width: 100%;
                color: #ffffff;
                background: #ff6d00;
                border-radius: 0.3rem;
              }
              > div:nth-child(3) {
                margin-top: 1rem;
                font-size: 1rem;
                line-height: 1.8rem;
              }
            }
          }
          .el-carousel__indicators {
            position: absolute;
            bottom: -3rem;
            .el-carousel__indicator {
              .el-carousel__button {
                width: 0.7rem;
                height: 0.7rem;
                border-radius: 50%;
                background-color: #bebebe;
              }
              &.is-active button {
                background-color: #ff6d00;
              }
            }
          }
        }
      }
    }
  }
  // 合作伙伴
  .home-partner {
    margin: 0 auto;
    width: 90%;
    margin-top: 4rem;
    .partnerList {
      overflow: hidden;
      zoom: 1;
      margin-top: 1.5rem;
      width: 100%;
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      flex-wrap: wrap;
      .partner-item-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46%;
        height: 5.5rem;
        border: 0.1rem solid #bebebe;
        &:nth-child(even) {
          margin-left: 4.2%;
        }
        &:nth-of-type(n + 3) {
          margin-top: 1rem;
        }
      }
    }
  }
  // 企业动向
  .home-Enterprise-trends {
    margin-top: 2rem;
    .trendsList {
      margin: 0 auto;
      margin-top: 2rem;
      width: 90%;
      > a {
        display: flex;
        height: 3.54rem;
        padding: 1rem;
        border: 0.1px dashed #ff6e01;
        box-shadow: 0.4rem 0.6rem 0.7rem 0.1rem rgba(255, 110, 1, 0.1);
        border-radius: 0.3rem;
        &.el-link--default:hover {
          color: #ff6d00;
        }
        &:nth-child(n + 2) {
          margin-top: 1.5rem;
        }
        ::v-deep {
          > span {
            width: 100%;
            display: flex;
            align-items: center;
            > div {
              &:nth-child(1) {
                display: flex;
                align-items: center;
                width: 25%;
                height: 40%;
              }
              &:nth-child(2) {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding-left: 1rem;
                width: 70%;
                > p:nth-child(1) {
                  font-size: 0.8rem;
                }
                > p:nth-child(2) {
                  font-size: 0.9rem;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
              &:nth-child(3) {
                color: #ff6d00;
                > i {
                  font-size: 1.2rem;
                }
              }
            }
          }
        }
      }
    }
  }
  .Footer {
    margin-top: 3rem;
    text-align: center;
    background-size: 100% 100% !important;
    height: 16rem;
    > div {
      color: #ffffff;
      padding: 1.2rem;
      font-size: 1rem;
      p:nth-child(1) {
        font-size: 1.6rem;
      }
      p:nth-child(2) {
        margin-top: 0.8rem;
        line-height: 1.6rem;
      }
      P:nth-child(3) {
        margin-top: 0.8rem;
        margin-bottom: 1.1rem;
      }
    }
  }
}

.bom-but {
  margin-top: 2rem;
  width: 100%;
  color: #ff6e01;
  font-size: 1.2rem;
}

.bg {
  background-size: 100% 100% !important;
}
</style>