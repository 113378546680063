<template>
  <div class="user-case">
    <div class="public-top">
      <div class="public-img">
        <p>华美实战案例</p>
        <p>我们为多个品牌打造过独一无二的成功案例</p>
      </div>
    </div>
    <div class="user-case-item">
      <waterfall class="caseList"
                 :col="2"
                 :data="caseList"
                 :gutterWidth="30">
        <template v-for="(item,index) in caseList">
          <el-card :key="index">
            <XRouterLink :to="item.hrefs?item.hrefs:`/MobileCase/MobileCase-details/${item.id}`"
                         :isindex="item.hrefs?0:1">
              <div class="tzimg">
                <el-image :src="item.titleimg"
                          fit="cover"></el-image>
              </div>
              <div class="content">
                <div>{{item.label}}</div>
                <div v-html="item.title"></div>
                <div>查看详情></div>
              </div>
            </XRouterLink>
            <!-- <router-link :to="`/MobileCase/MobileCase-details/${item.id}`">
              <div class="tzimg">
                <el-image :src="item.titleimg"
                          fit="cover"></el-image>
              </div>
              <div class="content">
                <div>{{item.label}}</div>
                <div v-html="item.title"></div>
                <div>查看详情></div>
              </div>
            </router-link> -->
          </el-card>
        </template>
      </waterfall>
    </div>
  </div>
</template>

<script>
import { caseList } from '@/api/home'
import XRouterLink from '@/components/XRouterLink'

export default {
  name: 'user-case',
  components: { XRouterLink },
  data () {
    return {
      currency_top: 'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/Mobile-currency-top.png',
      caseList: caseList()
    }
  }
}
</script>

<style lang="scss" scoped>
.user-case {
  background: #f1f3f5;
  .user-case-item {
    margin: 0 auto;
    width: 90%;
    margin-top: 1.2rem;
    margin-bottom: 1.1rem;
    .caseList {
      margin-top: 1.2rem;
      ::v-deep {
        .vue-waterfall-column {
          width: 47% !important;
          &:nth-child(even) {
            padding-right: 3%;
          }
          &:nth-child(odd) {
            padding-left: 3%;
          }
          > .el-card {
            border-radius: 0.4rem;
            & + .el-card {
              margin-top: 1.2rem;
            }
            .el-card__body {
              padding: 0;
              a {
                display: block;
                width: 100%;
                height: 100%;
                text-decoration: none;
                .tzimg {
                  width: 100%;
                  .el-image {
                    .el-image__inner {
                      opacity: 1;
                    }
                  }
                }
                .content {
                  padding: 0.8rem;
                  > div:nth-child(1) {
                    margin-bottom: 0.6rem;
                    font-size: 1.2rem;
                    font-weight: 400;
                    color: #333333;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                  > div:nth-child(2) {
                    margin-bottom: 1.2rem;
                    font-size: 0.7rem;
                    font-weight: 200;
                    color: #666666;
                    line-height: 1.2rem;
                    letter-spacing: 0.05rem;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 5;
                    overflow: hidden;
                  }
                  > div:nth-child(3) {
                    margin-bottom: 0.5rem;
                    text-align: right;
                    font-size: 0.8rem;
                    font-weight: 200;
                    color: #666666;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>